<template>
    <table id="example" class="display table" style="min-width: 845px">
        <thead>
            <tr>
                <th @click="sortData('numero_factura')">Factura<i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('cliente_name')">Cliente <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('deudor_name')">Deudor <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('importe')">Importe <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('ruta_name')">Ruta <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('fecha_factura')">Fecha documento <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('fecha_programacion')">Fecha programacion <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('status_factura_name')" style="text-align: center;">Estatus <i class="fa fa-fw fa-sort"></i></th>
                <th v-if="showPermission.includes('editar facturas')" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody  v-if="facturas.length > 0 && loading == true">
            <tr v-for="( factura, index) in facturas" :key="index" :value="factura.id">
                <td>{{ factura.numero_factura }}</td>
                <td>{{ factura.cliente.razon_social }}</td>
                <td>{{ factura.deudor.razon_social }}</td>
                <td>${{ factura.importe }}</td>
                <td>{{ factura.ruta.ruta }}</td>
                <td>{{ factura.fecha_factura }}</td>
                <td>{{ factura.fecha_programacion }}</td>
                <td style="text-align: center;">
                    <span class="badge badge-success light border-0">{{ factura.status_factura.nombre }}</span>
                </td>
                <td class="text-center" v-if="showPermission.includes('editar facturas')">
                    <div class="dropdown">
                        <button type="button" class="btn light sharp" data-bs-toggle="dropdown">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
                        </button>
                        <div class="dropdown-menu">
                            <a data-bs-toggle="modal" data-bs-target="#ModalEditFactura" @click="datosFactura(factura)" class="dropdown-item" role="button">Editar</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <component v-if="loading == false" :is="ShowNotDataTableComponent">Cargando facturas...</component>
    <component v-if="facturas.length == 0 && loading ==true" :is="ShowNotDataTableComponent">No existen facturas en estos momentos...</component>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import {sortList} from '@/services/Functions'
import ShowNotDataTableComponent from '@/components/helpers/ShowNotDataTableComponent.vue'

let emit = defineEmits(['update:facturas','sendDatosFactura'])
const props = defineProps(['facturas','showData'])


const sortData = (column) => {
    emit('update:facturas', sortList(column, props.facturas));
}

const datosFactura = (factura) => {
    let data        = new Object()
    data.id         = factura.id
    data.numero_factura = factura.numero_factura
    data.cliente_id = factura.cliente.id
    data.deudor_id  = factura.deudor.id
    data.importe    = factura.importe
    data.moneda_id  = factura.moneda.id
    data.saldo      = factura.saldo
    data.ruta_id    = factura.ruta.id
    data.fecha_factura      = factura.fecha_factura_format
    data.fecha_programacion = factura.fecha_programacion_format
    data.status_factura     = factura.status_factura.id

    emit('sendDatosFactura', data)
}

const facturas = computed(() => {
    return props.facturas
})

const loading = computed(() => {
    return props.showData
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>
<style scoped>
.fa.fa-fw.fa-sort {
    font-size: 68%;
    cursor: pointer;
}
</style>