<template>
    <div class="dataTables_info">
        <div class="col-12 d-flex justify-content-center align-items-center">
            <span class="text-span"><slot></slot></span>
        </div>
    </div>    
</template>
<style scoped>
.text-span{
    font-size: 13px;
    font-weight: 400;
    padding: 0.625rem 1rem;
}
</style>