import api from '@/services/api'

export const storeRutaService = async (data) => {
    return api().post('v1/rutas/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getRutaService = async (page, search) => {
    return api().get('v1/rutas/', {
        params: {
            page: page,
            search:search
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const editRutaService = async ( id, data) => {
    return api().post('v1/rutas/edit/'+ id, data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const updateRutaService = async (id, data) => {
    return api().post('v1/rutas/update-status/' + id, data).then((response) => {
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const storeRutaMasiveService = async (data) => {
    return api().post('v1/rutas/masive', data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}