<template>  
  <div v-show="nameRutaActual === 'login'" class="container-fluid h-100">
    <router-view v-if="nameRutaActual === 'login'"></router-view>
  </div>

  <div v-show="nameRutaActual !== 'login'" id="main-wrapper">
      <component :is="NavBarTop" v-if="nameRutaActual !== 'login'"/>
      <component :is="NavBarMenu" v-if="nameRutaActual !== 'login'"/>
      <div class="content-body">
        <component :is="ContentPage" :path="nameRutaActual"/>
        <router-view :path="nameRutaActual" v-if="nameRutaActual !== 'login'"></router-view>
      </div>
      <component :is="FooterComponent" v-if="nameRutaActual !== 'login'"/>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router'
  import { computed } from 'vue'
  import NavBarTop from './components/NavBarTop.vue'
  import NavBarMenu from './components/NavBarMenu.vue'
  import FooterComponent from './components/FooterComponent.vue'
  import ContentPage from './components/ContentPage.vue'

  const route         = useRoute()
  const nameRutaActual= computed(() => {
    return route.name
  })
</script>
<style>
.form-select-lg{
  height: 2.813rem !important;
  font-size: 13px !important;
  color: #6e6e6e !important;
  border-color: #CCCCCC !important;
}

.stylePaddingButton{
  margin-right: 0.5rem !important;
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .buttons-div{
      padding-top: 5%;
      /*align-items: center;
      display: flex;
      justify-content: center;*/
  }

  .stylePaddingButton{
    width: 45%;
    height: 50%;
  }

  .button-2{
    margin-right: 0% !important;
    margin-left: 7.9%;
  }

  .button-3{
    margin-top: 2%;
    width: 45%;
    height: 50%;
  }

  .dataTables_filter{
    width: 45%;
  }
}

@media (max-width: 395px) {
  .buttons-div{
      padding-top: 5%;
      /*align-items: center;
      display: flex;
      justify-content: center;*/
  }

  .stylePaddingButton{
    width: 49.3%;
    height: 50%;
    margin-right: 0px !important;
  }

  .button-2{
    margin-right: 0% !important;
    margin-left: 1%;
  }

  .button-3{
    margin-top: 2%;
    width: 49.3%;
    height: 50%;
  }

  .dataTables_filter{
    width: 50%;
  }
}
</style>