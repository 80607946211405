<template>
    <div class="modal fade" id="ModalEditRuta" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Editar Ruta</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Nombre <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="rutas.value.ruta" placeholder="Ingrese una ruta">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalEditRuta">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="editarRuta">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref  } from 'vue'
import { responseAxios } from '@/Helper';
import { editRutaService } from '@/services/rutas'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

const props = defineProps(['rutas'])
let loading = ref(false)
let emit = defineEmits(['recargarDataRutas'])

const editarRuta = async () => {
    loading.value = true
    let data = new FormData();
    data.append('id', rutas.value.value.id)
    data.append('nombre', rutas.value.value.ruta)

    const response = await editRutaService(rutas.value.value.id, data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit("recargarDataRutas",1)
        document.getElementById('btnCerrarModalEditRuta').click()
    }
}

const rutas = computed(() => {
    return props.rutas
})
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>