import api from '@/services/api'

export const getRolesService = async () => {
    return api().get('v1/roles/', {
        params:{},
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}