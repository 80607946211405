<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive active-projects style-1">
                            <div class="tbl-caption">
                                <h4 class="heading mb-0">{{ nameRutaActual }}</h4>
                                <div id="example_filter" class="dataTables_filter">
                                    <input type="search" class="form-control form-control-sm"
                                        placeholder="Search"
                                        @keyup="busqueda"
                                        v-model="buscar"
                                        aria-controls="example" />
                                </div>
                                <div v-if="showPermission.includes('crear facturas')" class="buttons-div">
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-1" data-bs-toggle="modal"
                                        data-bs-target="#ModalAddFactura">
                                        + Añadir Factura
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-2" data-bs-toggle="modal"
                                    data-bs-target="#ModalAddMasiveData">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3" ></span>
                                        Carga Facturas
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm button-3" @click="downloadFormatoXLSX">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3"></span>
                                        Descargar formato
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <component :is="TableFacturasComponent" 
                                :facturas="facturas"
                                :showData="loading"
                                @sendDatosFactura="datosFactura($event)"
                                />
                            </div>
                            <!-- Paginación -->
                            <div class="dataTables_info" id="example_info" role="status" aria-live="polite"
                                style="padding-top: 20px;">
                                Mostrando {{ pagination.to }} de {{ pagination.total }} registros
                            </div>
                            <div class="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                <component :is="PaginacionComponent" :total="pagination.total"
                                    :current_page="pagination.current_page" :per_page="pagination.per_page"
                                    :last_page="pagination.last_page" :from="pagination.from" :to="pagination.to"
                                    :offset="offset" @changePage="getAllFacturas">
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="ModalAddFacturasComponent" @recargarDataFacturas="getAllFacturas($event)"/>
        <component :is="ModalEditFacturasComponent" :facturas="factura_data" @recargarDataFacturas="getAllFacturas($event)"/>
        <component :is="ModalAddMasiveDataComponent" :loadingErrors="loadingErrors" :nameFileXLSX="nameFileXLSX">
            <template v-slot:header>Carga masiva de facturas</template>
            <template v-slot:body>
                <input class="form-control" type="file" id="formFile" @change="changedFiles">
            </template>
            <template v-slot:footer>
                <button v-if="loadingButton == false" type="button" class="btn btn-primary" @click="sendDataMasive">Guardar</button>
                <component v-else :is="ButtonLoadingComponent">Guardando</component>
            </template>
        </component>
    </div>
</template>
<script setup>
import {defineProps, computed, reactive, ref, onMounted} from 'vue'
import TableFacturasComponent from '@/components/Facturas/TableFacturasComponent'
import ModalAddFacturasComponent from '@/components/Facturas/ModalAddFacturasComponent'
import ModalEditFacturasComponent from '@/components/Facturas/ModalEditFacturasComponent'
//import ModalAddFilesComponent from '@/components/Facturas/ModalAddFilesComponent'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
import ModalAddMasiveDataComponent from '@/components/helpers/ModalAddMasiveDataComponent.vue'
import { responseAxios } from '@/Helper'
import PaginacionComponent from '@/components/helpers/PaginacionComponent.vue'
import { capitalize,downloadFormatXLSX } from '@/services/Functions'
import {getFacturaService, storeFacturaMasiveService } from '@/services/facturas'

let loadingButton = ref(false)
let loadingErrors = ref(false)
let nameFileXLSX = 'ErrorFacturas.xlsx'
let files = ref(null)

let loading = ref(false)
let facturas = ref([])
let factura_edit = ref([])
let pagination = reactive({
    total: 0,
    current_page: 0,
    per_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
})
let buscar = ''
let offset = ref(3)
const props = defineProps(['path'])

onMounted(() => {
    getAllFacturas()
})

const getAllFacturas = async (page = 1, search = '') => {
    loading.value = false
    let response = await getFacturaService(page, search)
    loading.value = true
    console.log(response.data.data)
    if (response.status == 200) {
        facturas.value = response.data.data
        pagination.total = response.data.meta.total;
        pagination.current_page = response.data.meta.current_page;
        pagination.per_page = response.data.meta.per_page;
        pagination.last_page = response.data.meta.last_page;
        pagination.from = response.data.meta.from;
        pagination.to = response.data.meta.to;
    }
}

const downloadFormatoXLSX = async () => {
    let data = new FormData()
    data.append('header', JSON.stringify(['numero_documento','cliente','deudor','ruta','importe','saldo','moneda','fecha_documento','fecha_programacion']))
    data.append('nombre', 'facturas_formato_masivo.xlsx')

    let response = await downloadFormatXLSX(data);

    var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const link = document.createElement('a');
    link.href = mediaType+response.data;
    link.href = mediaType+response.data;
    link.setAttribute('download', 'facturas_formato_masivo.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const changedFiles = (event) => {
    files.value = event.target.files[0]
}

const sendDataMasive = async () => {
    loadingButton.value = true
    loadingErrors.value = false
    let data = new FormData()
    data.append('file', files.value)

    let response = await storeFacturaMasiveService(data);
    responseAxios(response)
    loadingButton.value = false
    if(response.status == 200){
        loadingErrors.value = false
        document.getElementById('btnCerrarModalFiles').click()
        document.getElementById('formFile').value ='';
        files.value = ''
        getAllFacturas()
    }

    if(response.status != 200){
        loadingErrors.value = true
    }
}

const datosFactura = (factura) => {
    factura_edit.value = factura
}

const nameRutaActual= computed(() => {
    return capitalize(props.path)
})
const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})

const factura_data= computed(() => {
    return factura_edit
})

const busqueda = () => {
    getAllFacturas(1, buscar)
}
</script>