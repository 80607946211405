<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive active-projects style-1">
                            <div class="tbl-caption">
                                <h4 class="heading mb-0">{{ nameRutaActual }}</h4>
                                <div id="example_filter" class="dataTables_filter">
                                    <input type="search"
                                            class="form-control form-control-sm" 
                                            placeholder="Search"
                                            @keyup="busqueda"
                                            v-model="buscar"
                                            aria-controls="example" />
                                </div>
                                <div v-if="showPermission.includes('crear empresas')" class="buttons-div">
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-1" data-bs-toggle="modal"
                                        data-bs-target="#ModalNewCompany">
                                        + Nueva Empresa
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-2" data-bs-toggle="modal"
                                    data-bs-target="#ModalAddMasiveData">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3" ></span>
                                        Carga Empresas
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm button-3" @click="downloadFormatoXLSX">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3"></span>
                                        Descargar formato
                                    </button>
                                </div>
                            </div>
                            <component 
                                :is="TableCompanyComponent" 
                                :empresas="empresas"
                                :showData = "loading"
                                @cargarDatosEmpresa="getAllCompany($event)" 
                                @sendDatosEmpresa="datosEmpresa($event)"
                            />
                            <!-- Paginación -->
                            <div class="dataTables_info" id="example_info" role="status" aria-live="polite"
                                style="padding-top: 20px;">
                                Mostrando {{ pagination.to }} de {{ pagination.total }} registros
                            </div>
                            <div class="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                <component :is="PaginacionComponent" :total="pagination.total"
                                    :current_page="pagination.current_page" :per_page="pagination.per_page"
                                    :last_page="pagination.last_page" :from="pagination.from" :to="pagination.to"
                                    :offset="offset" @changePage="getAllCompany">
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="ModalAddCompanyComponent" @cargarDatosEmpresa="getAllCompany" />
        <component :is="ModalEditCompanyComponent" :datos_empresa="computedEmpresa"
            @cargarDatosEmpresa="getAllCompany(1)" />
        <component :is="ModalAddMasiveDataComponent" :loadingErrors="loadingErrors" :nameFileXLSX="nameFileXLSX">
            <template v-slot:header>Carga masiva de rutas</template>
            <template v-slot:body>
                <input class="form-control" type="file" id="formFile" @change="changedFiles">
            </template>
            <template v-slot:footer>
                <button v-if="loadingButton == false" type="button" class="btn btn-primary" @click="sendDataMasive">Guardar</button>
                <component v-else :is="ButtonLoadingComponent">Guardando</component>
            </template>
        </component>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive, defineProps, computed } from 'vue'
import { getCompanyService, storeCompanyMasiveService } from '@/services/empresas'
import { capitalize, downloadFormatXLSX } from '@/services/Functions'
import TableCompanyComponent from '@/components/Company/TableCompanyComponent'
import ModalAddCompanyComponent from '@/components/Company/ModalAddCompanyComponent'
import ModalEditCompanyComponent from '@/components/Company/ModalEditCompanyComponent.vue'
import PaginacionComponent from '@/components/helpers/PaginacionComponent'
import ModalAddMasiveDataComponent from '@/components/helpers/ModalAddMasiveDataComponent.vue'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
import { responseAxios } from '@/Helper'

let loadingButton = ref(false)
let loadingErrors = ref(false)
let files = ref(null)
let nameFileXLSX = 'ErrorEmpresas.xlsx'

let empresas = ref([])
let loading = ref(false)
let datos_empresa = ref([])
let pagination = reactive({
    total: 0,
    current_page: 0,
    per_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
})
let offset = ref(3)
let buscar = ''

onMounted(() => {
    getAllCompany()
})

const getAllCompany = async (page = 1, search = '') => {
    loading.value = false
    let response = await getCompanyService(page, search)
    loading.value = true
    if (response.status == 200) {
        empresas.value = response.data.data
        pagination.total = response.data.meta.total;
        pagination.current_page = response.data.meta.current_page;
        pagination.per_page = response.data.meta.per_page;
        pagination.last_page = response.data.meta.last_page;
        pagination.from = response.data.meta.from;
        pagination.to = response.data.meta.to;
    }
}

const datosEmpresa = (empresa) => {
    datos_empresa.value = empresa;
}

const downloadFormatoXLSX = async () => {
    let data = new FormData()
    data.append('header', JSON.stringify(['Razon_social','RFC','Fecha_ingreso','Fecha_firma_contrato','Sector','Comentarios']))
    data.append('nombre', 'formato_rutas_masiva.xlsx')

    let response = await downloadFormatXLSX(data);

    var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const link = document.createElement('a');
    link.href = mediaType+response.data;
    link.href = mediaType+response.data;
    link.setAttribute('download', 'formato_empresas_masiva.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const changedFiles = (event) => {
    files.value = event.target.files[0]
}

const sendDataMasive = async () => {
    loadingButton.value = true
    loadingErrors.value = false
    let data = new FormData()
    data.append('file', files.value)

    let response = await storeCompanyMasiveService(data);
    responseAxios(response)
    loadingButton.value = false
    if(response.status == 200){
        loadingErrors.value = false
        document.getElementById('btnCerrarModalFiles').click()
        document.getElementById('formFile').value ='';
        files.value = ''
        getAllCompany()
    }

    if(response.status != 200){
        loadingErrors.value = true
    }
}

const props = defineProps(['path'])
const nameRutaActual = computed(() => {
    return capitalize(props.path)
})
const computedEmpresa = computed(() => {
    return datos_empresa
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})

const busqueda = () => {
    getAllCompany(1, buscar)
}
</script>