import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '@/pages/LoginPage.vue'
import HomePage from '@/pages/HomePage.vue';
import CompanyPage from '@/pages/CompanyPage.vue';
import UserPage from '@/pages/UserPage'
import RutasPage from '@/pages/RutasPage'
import ClienteDeudorPage from '@/pages/ClienteDeudorPage'
import RutaGestorPage from '@/pages/RutaGestorPage'
import FacturaPage from '@/pages/FacturaPage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/company',
      name: 'empresa',
      component: CompanyPage
    },
    {
      path: '/users',
      name: 'usuarios',
      component: UserPage
    },
    {
      path: '/routes',
      name: 'rutas',
      component: RutasPage
    },
    {
      path: '/cliente/:id',
      name: 'cliente',
      component: ClienteDeudorPage
    },
    {
      path: '/ruta-gestor',
      name: 'gestores',
      component: RutaGestorPage
    },
    {
      path: '/facturas',
      name: 'facturas',
      component: FacturaPage
    }
  ]
})

router.beforeEach(async (to, from) => {
  
  if(sessionStorage.token != undefined && sessionStorage.token != ''){
    document.getElementById("app").classList.remove("authincation");
  }

  if ((sessionStorage.token == undefined || sessionStorage.token == '') && to.name !== 'login') {
      return { name: 'login' }
  }

  if ((sessionStorage.token != undefined && sessionStorage.token != '') && to.name === 'login') {
      console.log(from)
      return { name: 'home' }
  }
})

export default router
