import api from '@/services/api'

export const getRutaGestor = async (page, search) => {
    return api().get('v1/ruta-gestor/', {
        params:{
            page:page,
            search:search
        },
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const storeRutaGestorService = async (data) => {
    return api().post('v1/ruta-gestor/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getRutaNoRegistrada = async () => {
    return api().get('v1/ruta-gestor/rutas')
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getGestorNoRegistrado = async () => {
    return api().get('v1/ruta-gestor/gestores')
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const updateStatusRutaGestor = async (id, data) => {
    return api().post('v1/ruta-gestor/update-status/'+id,data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}