<template>
    <table id="empoloyees-tblwrapper" class="table table-responsive-md">
        <thead>
            <tr>
                <th @click="sortData('id')">ID <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('razon_social')">Razón social <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('sector_nombre')">Sector <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('rfc')">RFC <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('fecha_ingreso')" class="header-edit">Fecha ingreso <i class="fa fa-fw fa-sort"></i>
                </th>
                <th @click="sortData('fecha_firma_contrato')" class="header-edit">Firma contrato <i
                        class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('comentarios')" class="header-edit">Comentarios <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('status')" style="text-align: center;">Estatus <i class="fa fa-fw fa-sort"></i></th>
                <th v-if="showPermission.includes('editar empresas') || showPermission.includes('eliminar empresas') || showPermission.includes('ver deudores')" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody v-if="empresas.length > 0 && loading == true">
            <tr v-for="( empresa, index) in empresas" :key="index" :value="empresa.id">
                <td><span>{{ empresa.id }}</span></td>
                <td style="white-space: inherit;">
                    <span>{{ empresa.razon_social }}</span>
                </td>
                <td style="white-space: inherit;"><span>{{ empresa.sector.nombre }}</span></td>
                <td style="white-space: inherit;"><span class="text-primary">{{ empresa.rfc }}</span></td>
                <td style="white-space: inherit;">
                    <span>{{ empresa.fecha_ingreso }}</span>
                </td>
                <td style="white-space: inherit;">
                    <span>{{ empresa.fecha_firma_contrato }}</span>
                </td>
                <td style="white-space: inherit;">
                    <span>{{ empresa.comentarios }}</span>
                </td>
                <td style="text-align: center;">
                    <span v-if="empresa.status == true" class="badge badge-success light border-0">Activo</span>
                    <span v-else class="badge badge-danger light border-0">Inactivo</span>
                </td>
                <td class="text-center" v-if="showPermission.includes('editar empresas') || showPermission.includes('eliminar empresas') || showPermission.includes('ver deudores')">
                    <div class="dropdown">
                        <button type="button" class="btn light sharp" data-bs-toggle="dropdown">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
                        </button>
                        <div class="dropdown-menu">
                            <a  v-if="showPermission.includes('editar empresas')" @click="datosEmpresa(empresa)" data-bs-toggle="modal" data-bs-target="#ModalEditCompany" class="dropdown-item" role="button">Editar</a>
                            <a v-if="empresa.status && showPermission.includes('eliminar empresas')" @click="updateStatusEmpresa(empresa.status, empresa.id)" class="dropdown-item" role="button">Desactivar</a>
                            <a v-else-if="!empresa.status && showPermission.includes('eliminar empresas')" @click="updateStatusEmpresa(empresa.status, empresa.id)" class="dropdown-item" role="button">Activar</a>
                            <a v-if="showPermission.includes('ver deudores')" @click="router.push({path:'/cliente/'+empresa.id, query:{name:empresa.razon_social }})" class="dropdown-item" role="button">Agregar deudores</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <component v-if="loading == false" :is="ShowNotDataTableComponent">Cargando empresas...</component>
    <component v-if="empresas.length == 0 && loading ==true" :is="ShowNotDataTableComponent">No existen empresas en estos momentos...</component>
</template>
<script setup>
import { defineProps, computed, defineEmits} from 'vue'
import { useRouter } from 'vue-router'
import { updateCompanyService } from '@/services/empresas'
import { sweetDelete, responseAxios } from '@/Helper';
import {sortList} from '@/services/Functions'
import ShowNotDataTableComponent from '@/components/helpers/ShowNotDataTableComponent.vue'

const props = defineProps(['empresas','showData'])
const router    = useRouter()
let emit = defineEmits(['sendDatosEmpresa', 'cargarDatosEmpresa','update:empresas'])

const empresas = computed(() => {
    return props.empresas
})

const loading = computed(() => {
    return props.showData
})

const datosEmpresa = (empresa) => {
    let sendDatos = new Object();
    sendDatos.id = empresa.id
    sendDatos.razon_social = empresa.razon_social
    sendDatos.rfc = empresa.rfc
    sendDatos.sector_id = empresa.sector.id
    sendDatos.fecha_ingreso = empresa.fecha_ingreso_formato2;
    sendDatos.fecha_firma_contrato = (empresa.fecha_firma_contrato != '----------') ? empresa.fecha_firma_contrato_formato2 : ''
    sendDatos.comentarios = (empresa.comentarios != '----------') ? empresa.comentarios : ''

    emit('sendDatosEmpresa', sendDatos)
}

const sortData = (column) => {
    emit('update:empresas', sortList(column, props.empresas));
}

const updateStatusEmpresa = async (status, id) => {
    console.log(id)
    let titulo = ""
    let html = ""
    let icono = ""
    let confirmar_button = ""
    if (status) {
        titulo = '¿Estás seguro de desactivar esta empresa?'
        icono = 'info'
        confirmar_button = 'Si, desactivar'
    } else {
        titulo = '¿Estás seguro de activar esta empresa?'
        icono = 'info'
        confirmar_button = 'Si, activar'
    }

    sweetDelete(titulo, html, async () => {
        let data = new FormData()
        data.append('status', !status)
        const response = await updateCompanyService(id, data)
        responseAxios(response)
        if (response.status == 200) {
            emit("cargarDatosEmpresa", 1)
        }
    }, icono, confirmar_button)
}

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>
<style scoped>table {
    table-layout: fixed;
}

th,
td {
    border: 1px solid blue;
    width: 100px;
    white-space: inherit;
}

.fa.fa-fw.fa-sort {
    font-size: 68%;
    cursor: pointer;
}

@media only screen and (min-width: 160px) and (max-width: 668px) {
    .header-edit {
        white-space: inherit;
    }
}</style>