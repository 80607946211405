import axios from 'axios'
import api from '@/services/api'
import { googleLogout } from "vue3-google-login"

export const loginService = async (data) => {
    return api().post('v1/auth/login', data).then((response) => {

        sessionStorage.setItem('token', response.data.user.token)
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        sessionStorage.setItem('permission',JSON.stringify(response.data.user.permission))
        axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.user.token
        
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const loginGoogleService = async (data) => {
    return api().post('v1/auth/social/google', data).then((response) => {

        sessionStorage.setItem('token', response.data.user.token)
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        sessionStorage.setItem('permission',JSON.stringify(response.data.user.permission))
        axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.user.token
        
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const loginFacebookService = async (data) => {
    return api().post('v1/auth/social/facebook', data).then((response) => {

        sessionStorage.setItem('token', response.data.user.token)
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        sessionStorage.setItem('permission',JSON.stringify(response.data.user.permission))
        axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.user.token
        
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const getUserGoogleToken = async (code) => {
    return axios.post( "https://oauth2.googleapis.com/token", {
            code,
            client_id   : process.env.VUE_APP_GOOGLE_CLIENT_ID,
            client_secret: process.env.VUE_APP_GOOGLE_SECRET_KEY,
            redirect_uri: "postmessage",
            grant_type  : "authorization_code"
        }).then((response) => {
            return response
        }).catch(({ response }) => {
            return response
        })
}

export const getUserInfoProfileGoogle = async (accessToken) => {
    return axios.get( "https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      ).then((response) => {
            return response
        }).catch(({ response }) => {
            return response
        })
}

export const logoutService = async (data) => {
    return api().post('v1/auth/logout', data).then((response) => {

        const user  = JSON.parse(sessionStorage.getItem('user'))
        if(user.google_id){
            googleLogout()
        }

        if(user.facebook_id){
           console.log("cerrar fb")
        }

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('permission')
        axios.defaults.headers.common['Authorization'] = ''
        
        return response
    }).catch(({ response }) => {
        return response
    })
}