import api from '@/services/api'
let sortedbyASC = true

//Devuelve texto con la primera letra en mayuscula
export const capitalize = (text) => {
    return (text) ? text[0].toUpperCase() + text.substr(1) : ''
}

export const changeShowPass = function(show_id, id){
    document.getElementById(show_id).classList.add("active");

    let atribute = document.getElementById(id).getAttribute('type')
    if(atribute == 'password'){
        document.getElementById(id).setAttribute('type', 'text');
    }else if(atribute == 'text'){
        document.getElementById(id).setAttribute('type', 'password');
        document.getElementById(show_id).classList.remove("active");
    }  
}

export const sortList = (sortBy, data) => {
    if (sortedbyASC) {
        data.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedbyASC = false;
    } else {
        data.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedbyASC = true;
    }
}

export const downloadFormatXLSX = async (data) => {
    return api().post('v1/files-masive/download-format/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const downloadFileErrorXLSX = async (data) => {
    return api().post('v1/files-masive/download-errors/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}