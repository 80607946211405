<template>
    <table id="example" class="display table" style="min-width: 845px">
        <thead>
            <tr>
                <th @click="sortData('id')">ID <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('name_complete')">Gestor <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('ruta_name')">Ruta <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('status')" style="text-align: center;">Estatus <i class="fa fa-fw fa-sort"></i></th>
                <th v-if="showPermission.includes('eliminar gestores')" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody v-if="ruta_gestor.length > 0 && loading == true">
            <tr v-for="( data, index) in ruta_gestor" :key="index" :value="data.id">
                <td>{{ data.id }}</td>
                <td>{{ data.user.name_complete }}</td>
                <td>{{ data.ruta.ruta }}</td>
                <td style="text-align: center;">
                    <span v-if="data.status == true" class="badge badge-success light border-0">Activo</span>
                    <span v-else class="badge badge-danger light border-0">Inactivo</span>
                </td>
                <td class="text-center" v-if="showPermission.includes('eliminar gestores')">
                    <div class="dropdown">
                        <button type="button" class="btn light sharp" data-bs-toggle="dropdown">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
                        </button>
                        <div class="dropdown-menu">
                            <a v-if="data.status == true" @click="updateStatusData(data.id, data.status)" class="dropdown-item" role="button">Desactivar</a>
                            <a v-else-if="data.status == false" @click="updateStatusData(data.id, data.status)" class="dropdown-item" role="button">Activar</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <component v-if="loading == false" :is="ShowNotDataTableComponent">Cargando gestores...</component>
    <component v-if="ruta_gestor.length == 0 && loading ==true" :is="ShowNotDataTableComponent">No existen gestores en estos momentos...</component>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { sweetDelete, responseAxios } from '@/Helper';
import {sortList} from '@/services/Functions'
import {updateStatusRutaGestor} from '@/services/ruta_gestor'
import ShowNotDataTableComponent from '@/components/helpers/ShowNotDataTableComponent.vue'

const props = defineProps(['ruta_gestor','showData'])
let emit = defineEmits(['update:ruta_gestor','recargarDataGestor'])

const sortData = (column) => {
    emit('update:ruta_gestor', sortList(column, props.ruta_gestor));
}

const updateStatusData = async (id, status) => {
    let titulo = ""
    let html = ""
    let icono = ""
    let confirmar_button = ""
    if (status) {
        titulo = '¿Estás seguro de desactivar este registro?'
        icono = 'info'
        confirmar_button = 'Si, desactivar'
    } else {
        titulo = '¿Estás seguro de activar este registro?'
        icono = 'info'
        confirmar_button = 'Si, activar'
    }

    sweetDelete(titulo, html, async () => {
        let data = new FormData()
        data.append('status', !status)
        const response = await updateStatusRutaGestor(id, data)
        responseAxios(response)
        if (response.status == 200) {
            emit("recargarDataGestor", 1)
        }
    }, icono, confirmar_button)
}

const ruta_gestor = computed(() => {
    return props.ruta_gestor
})

const loading = computed(() => {
    return props.showData
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>
<style scoped>
.fa.fa-fw.fa-sort {
    font-size: 68%;
    cursor: pointer;
}
</style>