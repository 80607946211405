<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive active-projects style-1">
                            <div class="tbl-caption">
                                <h4 class="heading mb-0">{{ nameRutaActual }}</h4>
                                <div id="example_filter" class="dataTables_filter">
                                    <input type="search" class="form-control form-control-sm" placeholder="Search"
                                        @keyup="busqueda"
                                        v-model="buscar"
                                        aria-controls="example" />
                                </div>
                                <div v-if="showPermission.includes('crear usuarios')" class="buttons-div">
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-1" data-bs-toggle="modal"
                                        data-bs-target="#ModalNewUser">
                                        + Nuevo Usuario
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm stylePaddingButton button-2" data-bs-toggle="modal"
                                    data-bs-target="#ModalAddMasiveData">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3" ></span>
                                        Carga Usuarios
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm button-3" @click="downloadFormatoXLSX">
                                        <span data-v-163eeaae="" class="fas fa-file-export me-1" data-fa-transform="shrink-3"></span>
                                        Descargar formato
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <component :is="TableUserComponent" 
                                :users = "users" 
                                :showData = "loading"
                                @sendDatosUser="datosUsuario($event)"
                                @recargarDataUsers="getAllUser($event)"/>
                            </div>
                            <!-- Paginación -->
                            <div class="dataTables_info" id="example_info" role="status" aria-live="polite"
                                style="padding-top: 20px;">
                                Mostrando {{ pagination.to }} de {{ pagination.total }} registros
                            </div>
                            <div class="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                <component :is="PaginacionComponent" :total="pagination.total"
                                    :current_page="pagination.current_page" :per_page="pagination.per_page"
                                    :last_page="pagination.last_page" :from="pagination.from" :to="pagination.to"
                                    :offset="offset" @changePage="getAllUser">
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="ModalAddUserComponent" @recargarDataUsers="getAllUser($event)"/>
        <component :is="ModalEditUserComponentVue" :user="datos_usuario" @recargarDataUsers="getAllUser($event)"/>
        <component :is="ModalAddMasiveDataComponent" :loadingErrors="loadingErrors" :nameFileXLSX="nameFileXLSX">
            <template v-slot:header>Carga masiva de usuarios</template>
            <template v-slot:body>
                <input class="form-control" type="file" id="formFile" @change="changedFiles">
            </template>
            <template v-slot:footer>
                <button v-if="loadingButton == false" type="button" class="btn btn-primary" @click="sendDataMasive">Guardar</button>
                <component v-else :is="ButtonLoadingComponent">Guardando</component>
            </template>
        </component>
    </div>
</template>
<script setup>
import { ref, reactive, defineProps, computed, onMounted } from 'vue'
import TableUserComponent from '@/components/User/TableUserComponent'
import ModalAddUserComponent from '@/components/User/ModalAddUserComponent'
import ModalEditUserComponentVue from '@/components/User/ModalEditUserComponent.vue'
import PaginacionComponent from '@/components/helpers/PaginacionComponent'
import { capitalize, downloadFormatXLSX } from '@/services/Functions'
import { getUserService, storeUserMasiveService } from '@/services/user'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
import ModalAddMasiveDataComponent from '@/components/helpers/ModalAddMasiveDataComponent.vue'
import { responseAxios } from '@/Helper'

let loadingButton = ref(false)
let loadingErrors = ref(false)
let nameFileXLSX = 'ErrorUsuarios.xlsx'
let files = ref(null)

let loading = ref(false)
let users = ref([])
let buscar = ''
let data_user =  ref([])
let pagination = reactive({
    total: 0,
    current_page: 0,
    per_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
})
let offset = ref(3)
const props = defineProps(['path'])

onMounted(() => {
    getAllUser()
})

const getAllUser = async (page = 1, search = '') => {
    loading.value = false
    let response = await getUserService(page, search)
    loading.value = true
    if (response.status == 200) {
        users.value = response.data.data
        pagination.total = response.data.meta.total;
        pagination.current_page = response.data.meta.current_page;
        pagination.per_page = response.data.meta.per_page;
        pagination.last_page = response.data.meta.last_page;
        pagination.from = response.data.meta.from;
        pagination.to = response.data.meta.to;
    }
}

const downloadFormatoXLSX = async () => {
    let data = new FormData()
    data.append('header', JSON.stringify(['Nombre', 'Apellidos','Email','Telefono', 'Rol']))
    data.append('nombre', 'formato_usuarios_masivos.xlsx')

    let response = await downloadFormatXLSX(data);

    var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const link = document.createElement('a');
    link.href = mediaType+response.data;
    link.href = mediaType+response.data;
    link.setAttribute('download', 'formato_usuarios_masivos.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const changedFiles = (event) => {
    files.value = event.target.files[0]
}

const sendDataMasive = async () => {
    loadingButton.value = true
    loadingErrors.value = false
    let data = new FormData()
    data.append('file', files.value)

    let response = await storeUserMasiveService(data);
    responseAxios(response)
    loadingButton.value = false
    if(response.status == 200){
        loadingErrors.value = false
        document.getElementById('btnCerrarModalFiles').click()
        document.getElementById('formFile').value ='';
        files.value = ''
        getAllUser()
    }

    if(response.status != 200){
        loadingErrors.value = true
    }
}

const datosUsuario = (user) => {
    data_user.value = user
}

const datos_usuario = computed(() => {
    return data_user
})

const busqueda = () => {
    getAllUser(1, buscar)
}

const nameRutaActual= computed(() => {
    return capitalize(props.path)
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>