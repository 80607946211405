<template>
    <table id="example" class="display table" style="min-width: 845px">
        <thead>
            <tr>
                <th @click="sortData('id')">ID <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('ruta')">Nombre <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('status')" style="text-align: center;">Estatus <i class="fa fa-fw fa-sort"></i></th>
                <th v-if="showPermission.includes('editar rutas') || showPermission.includes('eliminar rutas')" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody v-if="rutas.length > 0 && loading == true">
            <tr v-for="( ruta, index) in rutas" :key="index" :value="ruta.id">
                <td>{{ ruta.id }}</td>
                <td>{{ ruta.ruta }}</td>
                <td style="text-align: center;">
                    <span v-if="ruta.status == true" class="badge badge-success light border-0">Activo</span>
                    <span v-else class="badge badge-danger light border-0">Inactivo</span>
                </td>
                <td class="text-center" v-if="showPermission.includes('editar rutas') || showPermission.includes('eliminar rutas')">
                    <div class="dropdown">
                        <button type="button" class="btn light sharp" data-bs-toggle="dropdown">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
                        </button>
                        <div class="dropdown-menu">
                            <a v-if="showPermission.includes('editar rutas')"  data-bs-toggle="modal" data-bs-target="#ModalEditRuta"  @click="datosRuta(ruta)" class="dropdown-item" role="button">Editar</a>
                            <a v-if="ruta.status == true && showPermission.includes('eliminar rutas')" @click="updateStatusRuta(ruta.id, ruta.status)" class="dropdown-item" role="button">Desactivar</a>
                            <a v-else-if="ruta.status == false && showPermission.includes('eliminar rutas')" @click="updateStatusRuta(ruta.id, ruta.status)" class="dropdown-item" role="button">Activar</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <component v-if="loading == false" :is="ShowNotDataTableComponent">Cargando rutas...</component>
    <component v-if="rutas.length == 0 && loading ==true" :is="ShowNotDataTableComponent">No existen rutas en estos momentos...</component>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { sweetDelete, responseAxios } from '@/Helper';
import {updateRutaService} from '@/services/rutas'
import {sortList} from '@/services/Functions'
import ShowNotDataTableComponent from '@/components/helpers/ShowNotDataTableComponent.vue'

const props = defineProps(['rutas','showData'])
let emit = defineEmits(['sendDatosRuta', 'recargarDataRutas', 'update:rutas'])

const datosRuta = (ruta) => {
    let data    = new Object()
    data.id     = ruta.id
    data.ruta   = ruta.ruta
    emit('sendDatosRuta', data)
}

const updateStatusRuta = async (id, status) => {
    let titulo = ""
    let html = ""
    let icono = ""
    let confirmar_button = ""
    if (status) {
        titulo = '¿Estás seguro de desactivar esta ruta?'
        icono = 'info'
        confirmar_button = 'Si, desactivar'
    } else {
        titulo = '¿Estás seguro de activar esta ruta?'
        icono = 'info'
        confirmar_button = 'Si, activar'
    }

    sweetDelete(titulo, html, async () => {
        let data = new FormData()
        data.append('status', !status)
        const response = await updateRutaService(id, data)
        responseAxios(response)
        if (response.status == 200) {
            emit("recargarDataRutas", 1)
        }
    }, icono, confirmar_button)
}

const sortData = (column) => {
    emit('update:rutas', sortList(column, props.rutas));
}

const rutas = computed(() => {
    return props.rutas
})

const loading = computed(() => {
    return props.showData
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})

</script>
<style scoped>
.fa.fa-fw.fa-sort {
    font-size: 68%;
    cursor: pointer;
}
</style>