<template>
    <div class="modal fade" id="ModalAddRutaGestor" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Añadir Ruta a Gestor</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="clearForm"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Gestor<span class="text-danger">*</span></label>
                            <select v-if="gestores.length > 0" class="form-select form-select-lg" v-model="gestor_value">
                                <option value="">Seleccione un gestor</option>
                                <option v-for="( gestor, index) in gestores" :key="index" :value="gestor.id">
                                    {{ gestor.name + " " + gestor.last_name }}
                                </option>
                            </select>
                            <select v-else class="default-select style-1 form-control" :value= "0" disabled>
                                <option value="0">No hay gestores disponibles</option>
                            </select>
                        </div>
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Ruta<span class="text-danger">*</span></label>
                            <select v-if="rutas.length > 0" class="form-select form-select-lg" v-model="ruta_value">
                                <option value="">Seleccione una ruta</option>
                                <option v-for="( ruta, index) in rutas" :key="index" :value="ruta.id">
                                    {{ ruta.ruta }}
                                </option>
                            </select>
                            <select v-else class="default-select style-1 form-control" :value= "0" disabled>
                                <option value="0">No hay rutas disponibles</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalAddRutaGestor" @click="clearForm">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="saveRutaGestor">Añadir</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref, defineEmits} from 'vue'
import { responseAxios } from '@/Helper'
import {getRutaNoRegistrada,getGestorNoRegistrado,storeRutaGestorService} from '@/services/ruta_gestor'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

let ruta_value = ref('')
let gestor_value = ref('')
let loading = ref(false)
let rutas = ref([])
let gestores = ref([])
let emit = defineEmits(['recargarDataGestor'])

onMounted(() => {
    getAllGestorNoRegistrado()
    getAllRutaNoRegistrada()
})

const getAllGestorNoRegistrado = async () => {
    let response = await getGestorNoRegistrado()
    if (response.status == 200) {
        gestores.value = response.data
    }
}

const getAllRutaNoRegistrada = async () => {
    let response = await getRutaNoRegistrada()
    if (response.status == 200) {
        rutas.value = response.data
    }
}

const saveRutaGestor = async () => {
    loading.value = true
    let data = new FormData()
    data.append('ruta_id', ruta_value.value)
    data.append('gestor_id', gestor_value.value)

    let response = await storeRutaGestorService(data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit('recargarDataGestor', 1)
        document.getElementById('btnCerrarModalAddRutaGestor').click()
    }
    clearForm()
    getAllGestorNoRegistrado()
    getAllRutaNoRegistrada()
}

const clearForm = () => {
    ruta_value.value    = ''
    gestor_value.value  = ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>