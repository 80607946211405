import api from '@/services/api'

export const storeUserService = async (data) => {
    return api().post('v1/user/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getUserService = async (page, search) => {
    return api().get('v1/user/', {
        params: {
            page: page,
            search:search
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const editUserService = async ( id, data) => {
    return api().post('v1/user/edit/'+ id, data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const updateUserService = async (id, data) => {
    return api().post('v1/user/update-status/' + id, data).then((response) => {
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const storeUserMasiveService = async (data) => {
    return api().post('v1/user/masive', data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}