import api from '@/services/api'

export const storeCompanyService = async (data) => {
    return api().post('v1/empresa/', data).then((response) => {
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const getCompanyService = async (page, search) => {
    return api().get('v1/empresa/', {
        params:{
            page:page,
            search:search
        },
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const editCompanyService = async (id, data) => {
    return api().post('v1/empresa/edit/' + id, data).then((response) => {
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const updateCompanyService = async (id, data) => {
    return api().post('v1/empresa/update-status/' + id, data).then((response) => {
        return response
    }).catch(({ response }) => {
        return response
    })
}

export const storeCompanyMasiveService = async (data) => {
    return api().post('v1/empresa/masive', data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}