<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive active-projects style-1">
                            <div class="tbl-caption">
                                <h4 class="heading mb-0">{{ nameRutaActual }}</h4>
                                <div id="example_filter" class="dataTables_filter">
                                    <input type="search" class="form-control form-control-sm"
                                        placeholder="Search"
                                        @keyup="busqueda"
                                        v-model="buscar"
                                        aria-controls="example" />
                                </div>
                                <div v-if="showPermission.includes('crear gestores')" style="padding-top: 1%;">
                                    <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#ModalAddRutaGestor">
                                        + Nuevo Registro
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <component :is="TableRutaGestorComponent" 
                                :ruta_gestor="ruta_gestor" 
                                :showData = "loading"
                                @recargarDataGestor="getAllRutaGestor($event)"/>
                            </div>
                            <!-- Paginación -->
                            <div class="dataTables_info" id="example_info" role="status" aria-live="polite"
                                style="padding-top: 20px;">
                                Mostrando {{ pagination.to }} de {{ pagination.total }} registros
                            </div>
                            <div class="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                <component :is="PaginacionComponent" :total="pagination.total"
                                    :current_page="pagination.current_page" :per_page="pagination.per_page"
                                    :last_page="pagination.last_page" :from="pagination.from" :to="pagination.to"
                                    :offset="offset" @changePage="getAllRutaGestor">
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="ModalAddRutaGestorComponent"  @recargarDataGestor="getAllRutaGestor($event)"/>
    </div>
</template>
<script setup>
import {defineProps, computed, reactive, ref, onMounted} from 'vue'
import TableRutaGestorComponent from '@/components/RutaGestor/TableRutaGestorComponent'
import PaginacionComponent from '@/components/helpers/PaginacionComponent.vue'
import ModalAddRutaGestorComponent from '@/components/RutaGestor/ModalAddRutaGestorComponent'
import { capitalize } from '@/services/Functions'
import {getRutaGestor} from '@/services/ruta_gestor'

let buscar = ''
let loading = ref(false)
let ruta_gestor = ref([])
let pagination = reactive({
    total: 0,
    current_page: 0,
    per_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
})
let offset = ref(3)
const props = defineProps(['path'])

onMounted(() => {
    getAllRutaGestor()
})

const getAllRutaGestor = async (page = 1, search = '') => {
    loading.value = false
    let response = await getRutaGestor(page, search)
    loading.value = true
    if (response.status == 200) {
        ruta_gestor.value = response.data.data
        pagination.total = response.data.meta.total;
        pagination.current_page = response.data.meta.current_page;
        pagination.per_page = response.data.meta.per_page;
        pagination.last_page = response.data.meta.last_page;
        pagination.from = response.data.meta.from;
        pagination.to = response.data.meta.to;
    }
}

const busqueda = () => {
    getAllRutaGestor(1, buscar)
}

const nameRutaActual= computed(() => {
    return capitalize(props.path)
})

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})

</script>