<template>
    <div class="modal fade" id="ModalEditFactura" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-lg mt-6">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Editar Factura</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Numero documento<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="facturas.value.numero_factura">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Importe<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="facturas.value.importe">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Saldo<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="facturas.value.saldo">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Cliente<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="facturas.value.cliente_id" @change="getAllDeudores(facturas.value.cliente_id)">
                                <option value="">Seleccione un cliente</option>
                                <option v-for="( cliente, index) in clientes" :key="index" :value="cliente.id">
                                    {{ cliente.razon_social }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Deudor<span class="text-danger">*</span></label>
                            <select v-if="deudores.length > 0" class="form-select form-select-lg" v-model="facturas.value.deudor_id">
                                <option value="">Seleccione un deudor</option>
                                <option v-for="( deudor, index) in deudores" :key="index" :value="deudor.deudor_id">
                                    {{ deudor.razon_social }}
                                </option>
                            </select>
                            <select v-else class="default-select style-1 form-control" v-model="facturas.value.deudor_id" disabled>
                                <option value="">No hay deudores disponibles</option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Ruta<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="facturas.value.ruta_id">
                                <option value="">Seleccione una ruta</option>
                                <option v-for="( ruta, index) in rutas" :key="index" :value="ruta.id">
                                    {{ ruta.ruta }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Moneda<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="facturas.value.moneda_id">
                                <option value="">Seleccione una moneda</option>
                                <option v-for="( moneda, index) in monedas" :key="index" :value="moneda.id">
                                    {{ moneda.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Fecha documento<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="facturas.value.fecha_factura">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Fecha programacion<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="facturas.value.fecha_programacion">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalEditFactura">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="editFactura">Editar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, computed, onMounted, ref, defineEmits } from 'vue'
import {getMonedasServices} from '@/services/monedas'
import {getRutaService} from '@/services/rutas'
import {getCompanyService} from '@/services/empresas'
import {getClienteDeudorService} from '@/services/cliente_deudor'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
import { responseAxios } from '@/Helper'
import {editFacturaService} from '@/services/facturas'

let emit = defineEmits(['recargarDataFacturas'])
const props = defineProps(['facturas'])
let monedas = ref([])
let rutas   = ref([])
let clientes= ref([])
let deudores= ref([])
let loading = ref(false)

onMounted(() => {
    getAllMonedas()
    getAllRutas()
    getAllClientes()
})

const getAllMonedas = async() => {
    let response = await getMonedasServices();
    if(response.status == 200){
        monedas.value = response.data
    }
}

const getAllRutas = async () => {
    let response = await getRutaService();
    if(response.status == 200){
        rutas.value = response.data.data
    }
}

const getAllClientes = async () => {
    let response = await getCompanyService();
    if(response.status == 200){
        clientes.value = response.data.data
    }
}

const getAllDeudores = async (cliente_id) => {
    console.log("Cliente: " + cliente_id)
    let response = await getClienteDeudorService(cliente_id);
    if(response.status == 200){
        deudores.value = response.data.data
    }
}

const editFactura = async () => {
    loading.value = true
    let data = new FormData()
    data.append('numero_documento',facturas.value.value.numero_factura)
    data.append('importe',facturas.value.value.importe)
    data.append('saldo', facturas.value.value.saldo)
    data.append('cliente_id', facturas.value.value.cliente_id)
    data.append('deudor_id',facturas.value.value.deudor_id)
    data.append('ruta_id',facturas.value.value.ruta_id)
    data.append('moneda_id', facturas.value.value.moneda_id)
    data.append('fecha_documento',facturas.value.value.fecha_factura)
    data.append('fecha_programacion',facturas.value.value.fecha_programacion)

    let response = await editFacturaService(facturas.value.value.id,data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit('recargarDataFacturas', 1)
        document.getElementById('btnCerrarModalEditFactura').click()
    }
}

const facturas = computed(() => {
    getAllDeudores(props.facturas.value.cliente_id)
    return props.facturas
})
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>