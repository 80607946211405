import api from '@/services/api'

export const getSectoresService = async () => {
    return api().get('v1/sector/', {
        params:{},
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}