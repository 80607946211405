<template>
    <div class="modal fade" id="ModalEditUser" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Editar Usuario</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Nombre <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="users.value.name"  placeholder="Ingrese un nombre">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Apellidos<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="users.value.last_name" placeholder="Ingrese sus apellidos">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Email<span
                                    class="text-danger">*</span></label>
                            <input type="email" class="form-control" v-model="users.value.email" placeholder="hello@example.com">
                        </div>
                        <div class="col-xl-6 mb-3 position-relative">
                            <label for="exampleFormControlInput2" class="form-label">Password</label>
                            <input type="password" class="form-control" v-model="users.value.password" id="passEditUser">
                            <span class="show-pass eye" id="showEyeEditUser" @click="changeShowPass('showEyeUser','passEditUser')">
                                <i class="fa fa-eye-slash"></i>
                                <i class="fa fa-eye"></i>
                            </span>
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Telefono</label>
                            <input type="text" class="form-control" v-model="users.value.telefono" placeholder="999999999">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label">Rol<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="users.value.rol">
                                <option value="">Seleccione un rol</option>
                                <option v-for="( role, index) in roles" :key="index" :value="role.id">
                                    {{ role.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalEditUser">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="editUser">Editar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, computed, defineProps, defineEmits } from 'vue'
import { getRolesService } from '@/services/roles'
import { editUserService } from '@/services/user'
import { changeShowPass } from '@/services/Functions'
import { responseAxios } from '@/Helper';
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

let roles   = ref([])
let loading = ref(false)
onMounted(() => {
    getAllRoles()
})

const props = defineProps(['user'])
let emit = defineEmits(['recargarDataUsers'])
const getAllRoles = async () => {
    let response = await getRolesService()
    if (response.status == 200) {
        roles.value = response.data
    }
}

const editUser = async () => {
    loading.value = true
    let data = new FormData();
    data.append('id', users.value.value.id)
    data.append('nombre', users.value.value.name)
    data.append('apellidos', users.value.value.last_name)
    data.append('email',users.value.value.email)
    data.append('password', users.value.value.password)
    data.append('telefono', users.value.value.telefono)
    data.append('role',users.value.value.rol)

    const response = await editUserService(users.value.value.id, data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit("recargarDataUsers",1)
        document.getElementById('btnCerrarModalEditUser').click()
    }
}

const users = computed(() => {
    return props.user
})
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>