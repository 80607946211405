<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive active-projects style-1">
                            <div class="tbl-caption">
                                <h4 class="heading mb-0">{{ param_name }}</h4>
                                <div id="example_filter" class="dataTables_filter">
                                    <input type="search" class="form-control form-control-sm"
                                        placeholder="Search"
                                        @keyup="busqueda"
                                        v-model="buscar"
                                        aria-controls="example" />
                                </div>
                                <div v-if="showPermission.includes('crear deudores')" class="buttons-div" style="padding-top: 1%;">
                                    <button type="button" class="btn btn-primary btn-sm " data-bs-toggle="modal"
                                        data-bs-target="#ModalAddClienteDeudores" @click="addDeudor">
                                        + Añadir Deudor
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <component :is="TableClienteDeudorComponent" 
                                :deudores="deudores" 
                                :showData = "loading"
                                @recargarDatos="getDeudores($event)"/>
                            </div>
                            <!-- Paginación -->
                            <div class="dataTables_info" id="example_info" role="status" aria-live="polite"
                                style="padding-top: 20px;">
                                Mostrando {{ pagination.to }} de {{ pagination.total }} registros
                            </div>
                            <div class="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                <component :is="PaginacionComponent" :total="pagination.total"
                                    :current_page="pagination.current_page" :per_page="pagination.per_page"
                                    :last_page="pagination.last_page" :from="pagination.from" :to="pagination.to"
                                    :offset="offset" @changePage="getDeudores">
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="ModalClienteDeudoresComponent" :datos_empresa="deudores_totales" @recargarDatos="getDeudores($event)" />
    </div>
</template>
<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import PaginacionComponent from '@/components/helpers/PaginacionComponent.vue'
import TableClienteDeudorComponent from '@/components/ClienteDeudor/TableClienteDeudorComponent'
import { useRoute } from 'vue-router'
import ModalClienteDeudoresComponent from '@/components/Company/ModalClienteDeudoresComponent.vue'
import {getClienteDeudorService, getAllDeudoresService} from '@/services/cliente_deudor'

const route   = useRoute()
let loading = ref(false)
let deudores = ref()
let deudores_disponibles = ref({
    deudores: [],
    id:0
})
let buscar = ''
let pagination = reactive({
    total: 0,
    current_page: 0,
    per_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
})
let offset = ref(3)

onMounted(() => {
  getDeudores();  
});

const getDeudores = async (page = 1, search = '') => {
    loading.value = false
    let response = await getClienteDeudorService(route.params.id, page, search)
    loading.value = true
    if (response.status == 200) {
        deudores.value = response.data.data
        pagination.total = response.data.meta.total;
        pagination.current_page = response.data.meta.current_page;
        pagination.per_page = response.data.meta.per_page;
        pagination.last_page = response.data.meta.last_page;
        pagination.from = response.data.meta.from;
        pagination.to = response.data.meta.to;
    }
}

const addDeudor = async () => {
    let response = await getAllDeudoresService(route.params.id)
    deudores_disponibles.value.id = route.params.id
    if(response.status == 200){
        deudores_disponibles.value.deudores = response.data
    }
}

const param_name = computed(() => {
    return (route.query) ? route.query.name : ''
})

const deudores_totales = computed(() => {
    return deudores_disponibles
})

const busqueda = () => {
    getDeudores(1, buscar)
}

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>
