export const initFBSDK = () => {
    return new Promise(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId : process.env.VUE_APP_FACEBOOK_CLIENT_ID,
                cookie: true,
                xfbml : true,
                version: process.env.VUE_APP_FACEBOOK_VERSION,
                apiKey: process.env.VUE_APP_SECRET_KEY
            });

            window.FB.getLoginStatus(function(response) {
              console.log(response)
              if(response.authResponse){
                return response.authResponse.userID
              }
          });};

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
  }