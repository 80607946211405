<template>
    <div>
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                
                <!-- Pagina anterior -->
                <li class="page-item" v-if="current_page > 1">
                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(current_page - 1)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>

                <li class="page-item" v-for="(page, index) in pagesNumber" :key="index" v-bind:class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click.prevent="changePage(page)">
                        {{ page }}
                    </a>
                </li>


                <li class="page-item" v-if="current_page < last_page">
                    <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(current_page + 1)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>

    </div>
</template>

<script>
export default {
    props: {
        total: Number,
        current_page: Number,
        per_page: Number,
        last_page: Number,
        from: Number,
        to: Number,
        offset: Number,
    },
    methods: {
        changePage(page){
            this.$emit('changePage', page)
        }
    },
    computed: {
        isActived: function () {
            return this.current_page;
        },
        pagesNumber: function () {
            if (!this.to) {
                return [];
            }
            var from = this.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + this.offset * 2;
            if (to >= this.last_page) {
                to = this.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
    },
};
</script>