<template>
    <button type="button" class="btn btn-primary"> <span class="loader"></span><span class="text-loader"><slot></slot></span></button>
</template>
<style scoped>
.text-loader{
    vertical-align: middle;
    margin-left: 4px;
}
.loader {
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border: 2px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>