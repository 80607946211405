import api from '@/services/api'

export const storeFacturaService = async (data) => {
    return api().post('v1/facturas/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getFacturaService = async (page, search) => {
    return api().get('v1/facturas/', {
        params: {
            page: page,
            search:search
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const editFacturaService = async (id, data) => {
    return api().post('v1/facturas/edit/'+id, data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const storeFacturaMasiveService = async (data) => {
    return api().post('v1/facturas/masive/', data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}