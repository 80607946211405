import api from '@/services/api'

export const getClienteDeudorService = async (id, page, search) => {
    return api().get('v1/cliente-deudor/index/' + id, {
        params: {
            page:page,
            search:search
        }
    })
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const getAllDeudoresService = async (id, data) => {
    return api().get('v1/cliente-deudor/' + id, data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const storeDeudoresService = async (data) => {
    return api().post('v1/cliente-deudor/', data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}

export const updateDeudoresService = async (id, data) => {
    return api().post('v1/cliente-deudor/update-status/'+id, data)
    .then((response) => {
        return response;
    }).catch(({ response }) => {
        return response;
    })
}