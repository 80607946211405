import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import router from './router/router';
import vue3GoogleLogin from 'vue3-google-login';

const app = createApp(App)
app.use(vue3GoogleLogin,{
    clientId : process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(router)
app.mount('#app')

if(sessionStorage.getItem('token') != undefined && sessionStorage.getItem('token') != ""){
    axios.defaults.headers.common['Authorization'] = "Bearer " + sessionStorage.getItem('token')
}