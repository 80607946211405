<template>
    <div class="modal fade" id="ModalNewUser" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Nuevo Usuario</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanUser"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Nombre <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="nombre" placeholder="Ingrese un nombre">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Apellidos<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="apellidos" placeholder="Ingrese sus apellidos">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Email<span
                                    class="text-danger">*</span></label>
                            <input type="email" class="form-control" v-model="email" placeholder="hello@example.com">
                        </div>
                        <div class="col-xl-6 mb-3 position-relative">
                            <label for="exampleFormControlInput2" class="form-label">Password<span
                                    class="text-danger">*</span></label>
                            <input type="password" class="form-control" v-model="password" id="passAddUser">
                            <span class="show-pass eye" id="showEyeUser" @click="changeShowPass('showEyeUser','passAddUser')">
                                <i class="fa fa-eye-slash"></i>
                                <i class="fa fa-eye"></i>
                            </span>
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label" >Telefono</label>
                            <input type="text" class="form-control" v-model="telefono" placeholder="999999999">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label">Rol<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="rol">
                                <option value="">Seleccione un rol</option>
                                <option v-for="( role, index) in roles" :key="index" :value="role.id">
                                    {{ role.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalAddUser" @click="cleanUser">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="saveUser">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, defineEmits } from 'vue'
import { responseAxios } from '@/Helper'
import { getRolesService } from '@/services/roles'
import { storeUserService } from '@/services/user'
import { changeShowPass } from '@/services/Functions'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'


let loading = ref(false)
let roles   = ref([])
let nombre  = ref('')
let apellidos = ref('')
let email   = ref('')
let password= ref('')
let telefono= ref('')
let rol     = ref('')
let emit = defineEmits(['recargarDataUsers'])

onMounted(() => {
    getAllRoles()
})

const getAllRoles = async () => {
    let response = await getRolesService()
    if (response.status == 200) {
        roles.value = response.data
    }
}

const saveUser = async() => {
    loading.value = true
    let data = new FormData()
    data.append('nombre', nombre.value)
    data.append('apellidos', apellidos.value)
    data.append('email', email.value)
    data.append('password', password.value)
    data.append('telefono', telefono.value)
    data.append('role', rol.value)

    let response = await storeUserService(data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        cleanUser()
        emit('recargarDataUsers', 1)
        document.getElementById('btnCerrarModalAddUser').click()
    }
}

const cleanUser = () => {
    nombre.value  = ''
    apellidos.value = ''
    email.value   = ''
    password.value= ''
    telefono.value= ''
    rol.value     = ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>