<template>
    <div class="modal fade" id="ModalAddClienteDeudores" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Añadir Deudor</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanData"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Deudor<span class="text-danger">*</span></label>
                            <select v-if="init.value.deudores.length > 0" class="form-select form-select-lg" v-model="deudor_id">
                                <option value="">Seleccione una empresa</option>
                                <option v-for="( empresa, index) in init.value.deudores" 
                                    :key="index"
                                    :value="empresa.id"
                                >
                                    {{ empresa.razon_social }}
                                </option>
                            </select>
                            <select v-else class="default-select style-1 form-control" :value= "0" disabled>
                                <option value="0">No hay deudores disponibles</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalClienteDeudor" @click="cleanData">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="addNewDeudor">Añadir</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref, computed, defineProps, defineEmits} from 'vue'
import {storeDeudoresService} from '@/services/cliente_deudor'
import { responseAxios } from '@/Helper';
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

let loading = ref(false)
let deudor_id   = ref('')
const props     = defineProps(['datos_empresa'])
let emit = defineEmits(['recargarDatos'])

const init  = computed(() => {
    return props.datos_empresa
})

const addNewDeudor = async () => {
    loading.value = true
    let data = new FormData()
    data.append('deudor_id', deudor_id.value)
    data.append('cliente_id', init.value.value.id)
    let response = await storeDeudoresService(data)
    loading.value = false
    deudor_id.value = '';
    
    responseAxios(response)
    if(response.status == 200){
        emit("recargarDatos",1)
        document.getElementById('btnCerrarModalClienteDeudor').click()
    }
}

const cleanData = () => {
    deudor_id.value = ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>