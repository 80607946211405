<template>
    <div class="modal fade" id="ModalAddMasiveData" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal"><slot name="header"></slot></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Seleccione un archivo excel/csv</label>
                            <slot name="body"></slot>
                        </div>
                        <div class="mb-3">
                            <a v-if="showErrors == true" class="text-danger" role="button" @click="downloadErrors">Errores de la insercion masiva</a>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalFiles">Cerrar</button>
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {defineProps, computed} from 'vue'
import {downloadFileErrorXLSX} from '@/services/Functions'

const props = defineProps(['loadingErrors', 'nameFileXLSX'])

const downloadErrors = async() => {
    let data = new FormData()
    data.append('nombre', nameFileXLSX.value)

    let response = await downloadFileErrorXLSX(data);

    var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const link = document.createElement('a');
    link.href = mediaType+response.data;
    link.href = mediaType+response.data;
    link.setAttribute('download', nameFileXLSX.value);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const nameFileXLSX= computed(() => {
    return props.nameFileXLSX
})

const showErrors= computed(() => {
    return props.loadingErrors
})
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>