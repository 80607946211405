<template>
    <div class="modal fade" id="ModalEditCompany" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Editar Empresa</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanProperties"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Razón social <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="init.value.razon_social" @input="$emit('update:init.value.razon_social', $event.target.value)" placeholder="">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label">RFC<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="init.value.rfc" @input="$emit('update:init.value.rfc', $event.target.value)" placeholder="">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label mt-3">Fecha ingreso<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="init.value.fecha_ingreso">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label mt-3">Fecha Firma Contrato</label>
                            <input class="form-control" type="date" v-model="init.value.fecha_firma_contrato">
                        </div>
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Sector<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="init.value.sector_id">
                                <option value="">Seleccione un sector</option>
                                <option v-for="( sector, index) in sectores" 
                                    :key="index"
                                    :value="sector.id"
                                >
                                    {{ sector.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Comentarios</label>
                            <textarea rows="3" class="form-control" v-model="init.value.comentarios"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalEditEmpresas" @click="cleanProperties">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="editCompany">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref, defineProps,computed, onMounted, defineEmits} from 'vue'
import {getSectoresService} from '@/services/sectores'
import {editCompanyService} from '@/services/empresas'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
import { responseAxios } from '@/Helper'

let sectores= ref([])
let loading = ref(false)
const props = defineProps(['datos_empresa'])
let emit    = defineEmits(['cargarDatosEmpresa'])

onMounted(() => {
    getAllSectores()
})

const getAllSectores = async () => {
    let response = await getSectoresService()
    if(response.status == 200){
        sectores.value = response.data
    }
}

const editCompany = async () =>{
    loading.value = true
    let data = new FormData()
    data.append('id', init.value.value.id)
    data.append('razon_social', init.value.value.razon_social)
    data.append('rfc', init.value.value.rfc)
    data.append('fecha_ingreso', init.value.value.fecha_ingreso)
    data.append('fecha_firma_contrato', init.value.value.fecha_firma_contrato)
    data.append('sector_id', init.value.value.sector_id)
    data.append('comentarios', init.value.value.comentarios)

    const response = await editCompanyService(init.value.value.id, data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit("cargarDatosEmpresa",1)
        document.getElementById('btnCerrarModalEditEmpresas').click()
    }
}

const init  = computed(() => {
    return props.datos_empresa
})
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>