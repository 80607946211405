<template>
    <div class="modal fade" id="ModalNewCompany" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Nueva Empresa</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanProperties"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Razón social <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="razonSocial" placeholder="">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label for="exampleFormControlInput2" class="form-label">RFC<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="rfc" placeholder="">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label mt-3">Fecha ingreso<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="fechaIngreso">
                        </div>
                        <div class="col-xl-6 mb-3">
                            <label class="form-label mt-3">Fecha Firma Contrato</label>
                            <input class="form-control" type="date" v-model="fechaFirmaContrato">
                        </div>
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Sector<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="sector">
                                <option value="">Seleccione un sector</option>
                                <option v-for="( sector, index) in sectores" 
                                    :key="index"
                                    :value="sector.id"
                                >
                                    {{ sector.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-12 mb-3">
                            <label class="form-label">Comentarios</label>
                            <textarea rows="3" class="form-control" v-model="comentarios"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalEmpresas" @click="cleanProperties">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="createdCompany">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref, onMounted, defineEmits} from 'vue'
import {getSectoresService} from '@/services/sectores'
import {storeCompanyService} from '@/services/empresas'
import { responseAxios } from '@/Helper'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

let loading = ref(false)
let sectores    = ref([])
let razonSocial = ref('')
let rfc         = ref('')
let fechaIngreso= ref('')
let fechaFirmaContrato = ref('')
let sector      = ref('')
let comentarios = ref('')
let emit = defineEmits(['cargarDatosEmpresa'])

onMounted(() => {
    cleanProperties()
    getAllSectores()
})

const getAllSectores = async () => {
    let response = await getSectoresService()
    if(response.status == 200){
        sectores.value = response.data
    }
}

const createdCompany = async () =>{
    loading.value = true
    let data = new FormData()
    data.append('razon_social', razonSocial.value)
    data.append('rfc', rfc.value)
    data.append('fecha_ingreso', fechaIngreso.value)
    data.append('fecha_firma_contrato', fechaFirmaContrato.value)
    data.append('sector_id', sector.value)
    data.append('comentarios', comentarios.value)

    const response = await storeCompanyService(data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        emit("cargarDatosEmpresa",1)
        cleanProperties()
        document.getElementById('btnCerrarModalEmpresas').click()
    }
}

const cleanProperties = () => {
    razonSocial.value   = ''
    rfc.value           = ''
    fechaIngreso.value  = ''
    fechaFirmaContrato.value = ''
    sector.value        = ''
    comentarios.value   = ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>