<template>
    <div class="modal fade" id="ModalAddFactura" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-lg mt-6">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Añadir Factura</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="clearProperties"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Numero documento<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="documento">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Importe<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="importe">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Saldo<span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="saldo">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Cliente<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="cliente_id" @change="getAllDeudores">
                                <option value="">Seleccione un cliente</option>
                                <option v-for="( cliente, index) in clientes" :key="index" :value="cliente.id">
                                    {{ cliente.razon_social }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Deudor<span class="text-danger">*</span></label>
                            <select v-if="deudores.length > 0" class="form-select form-select-lg" v-model="deudor_id">
                                <option value="">Seleccione un deudor</option>
                                <option v-for="( deudor, index) in deudores" :key="index" :value="deudor.deudor_id">
                                    {{ deudor.razon_social }}
                                </option>
                            </select>
                            <select v-else class="default-select style-1 form-control" v-model="deudor_id" disabled>
                                <option value="">No hay deudores disponibles</option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Ruta<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="ruta_id">
                                <option value="">Seleccione una ruta</option>
                                <option v-for="( ruta, index) in rutas" :key="index" :value="ruta.id">
                                    {{ ruta.ruta }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Moneda<span class="text-danger">*</span></label>
                            <select class="form-select form-select-lg" v-model="moneda_id">
                                <option value="">Seleccione una moneda</option>
                                <option v-for="( moneda, index) in monedas" :key="index" :value="moneda.id">
                                    {{ moneda.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Fecha documento<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="fecha_documento">
                        </div>
                        <div class="col-xl-4 mb-3">
                            <label class="form-label">Fecha programacion<span class="text-danger">*</span></label>
                            <input class="form-control" type="date" v-model="fecha_programacion">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalAddFactura" @click="clearProperties">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="saveFactura">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref, onMounted, defineEmits} from 'vue'
import {getMonedasServices} from '@/services/monedas'
import {getRutaService} from '@/services/rutas'
import {getCompanyService} from '@/services/empresas'
import {getClienteDeudorService} from '@/services/cliente_deudor'
import {storeFacturaService} from '@/services/facturas'
import { responseAxios } from '@/Helper'

import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'
let loading = ref(false)

let monedas = ref([])
let rutas   = ref([])
let clientes= ref([])
let deudores= ref([])

let documento   = ref('')
let importe     = ref('')
let saldo       = ref('')
let cliente_id  = ref('')
let deudor_id   = ref('')
let ruta_id     = ref('')
let moneda_id   = ref('')
let fecha_documento     = ref('')
let fecha_programacion  = ref('')

let emit    = defineEmits(['recargarDataFacturas'])

onMounted(() => {
    getAllMonedas()
    getAllRutas()
    getAllClientes()
})

const getAllMonedas = async() => {
    let response = await getMonedasServices();
    if(response.status == 200){
        monedas.value = response.data
    }
}

const getAllRutas = async () => {
    let response = await getRutaService();
    if(response.status == 200){
        rutas.value = response.data.data
    }
}

const getAllClientes = async () => {
    let response = await getCompanyService();
    if(response.status == 200){
        clientes.value = response.data.data
    }
}

const getAllDeudores = async () => {
    let response = await getClienteDeudorService(cliente_id.value);
    if(response.status == 200){
        deudores.value = response.data.data
    }
}

const saveFactura = async () => {
    loading.value = true
    let data = new FormData()
    data.append('numero_documento',documento.value)
    data.append('importe',importe.value)
    data.append('saldo', saldo.value)
    data.append('cliente_id', cliente_id.value)
    data.append('deudor_id',deudor_id.value)
    data.append('ruta_id',ruta_id.value)
    data.append('moneda_id', moneda_id.value)
    data.append('fecha_documento',fecha_documento.value)
    data.append('fecha_programacion',fecha_programacion.value)

    let response = await storeFacturaService(data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        clearProperties()
        emit('recargarDataFacturas', 1)
        document.getElementById('btnCerrarModalAddFactura').click()
    }
}

const clearProperties = () => {
    documento.value     = ''
    importe.value       = ''
    saldo.value         = ''
    cliente_id.value    = ''
    deudor_id.value     = ''
    ruta_id.value       = ''
    moneda_id.value     = ''
    fecha_documento.value   = ''
    fecha_programacion.value= ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>