<template>
    <table id="example" class="display table" style="min-width: 845px">
        <thead>
            <tr>
                <th @click="sortData('id')">ID <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('name_complete')">Nombre <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('email')">Email <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('telefono')">Telefono <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('rol_name')">Rol <i class="fa fa-fw fa-sort"></i></th>
                <th @click="sortData('status')" style="text-align: center;">Estatus <i class="fa fa-fw fa-sort"></i></th>
                <th v-if="showPermission.includes('editar usuarios') || showPermission.includes('eliminar usuarios')" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody v-if="users.length > 0 && loading == true">
            <tr v-for="( user, index) in users" :key="index" :value="user.id">
                <td>{{ user.id }}</td>
                <td>{{ user.name_complete }}</td>
                <td>{{ user. email }}</td>
                <td>{{ user.telefono }}</td>
                <td>{{ user.rol.name }}</td>
                <td style="text-align: center;">
                    <span v-if="user.status == true" class="badge badge-success light border-0">Activo</span>
                    <span v-else class="badge badge-danger light border-0">Inactivo</span>
                </td>
                <td class="text-center" v-if="showPermission.includes('editar usuarios') || showPermission.includes('eliminar usuarios')">
                    <div class="dropdown">
                        <button type="button" class="btn light sharp" data-bs-toggle="dropdown">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
                        </button>
                        <div class="dropdown-menu">
                            <a v-if="showPermission.includes('editar usuarios')"  data-bs-toggle="modal" data-bs-target="#ModalEditUser" @click="datosUsuario(user)" class="dropdown-item" role="button">Editar</a>
                            <a v-if="user.status == true && showPermission.includes('eliminar usuarios')" @click="updateStatusUser(user.status, user.id)" class="dropdown-item" role="button">Desactivar</a>
                            <a v-else-if="user.status == false && showPermission.includes('eliminar usuarios')" @click="updateStatusUser(user.status, user.id)" class="dropdown-item" role="button">Activar</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <component v-if="loading == false" :is="ShowNotDataTableComponent">Cargando usuarios...</component>
    <component v-if="users.length == 0 && loading ==true" :is="ShowNotDataTableComponent">No existen usuarios en estos momentos...</component>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { updateUserService } from '@/services/user'
import { sweetDelete, responseAxios } from '@/Helper';
import {sortList} from '@/services/Functions'
import ShowNotDataTableComponent from '@/components/helpers/ShowNotDataTableComponent.vue'


const props = defineProps(['users','showData'])
let emit = defineEmits(['sendDatosUser', 'recargarDataUsers', 'update:users'])

const users = computed(() => {
    return props.users
})

const loading = computed(() => {
    return props.showData
})

const datosUsuario = (user) => {
    let user_data       = new Object()
    user_data.id        = user.id
    user_data.name      = user.name
    user_data.last_name = user.last_name
    user_data.email     = user.email
    user_data.telefono  = (user.telefono != '----------') ? user.telefono : ''
    user_data.rol       = user.rol.id
    user_data.password  = ''

    emit('sendDatosUser', user_data)
}
 
const updateStatusUser = async (status, id) => {
    let titulo = ""
    let html = ""
    let icono = ""
    let confirmar_button = ""
    if (status) {
        titulo = '¿Estás seguro de desactivar este usuario?'
        icono = 'info'
        confirmar_button = 'Si, desactivar'
    } else {
        titulo = '¿Estás seguro de activar este usuario?'
        icono = 'info'
        confirmar_button = 'Si, activar'
    }

    sweetDelete(titulo, html, async () => {
        let data = new FormData()
        data.append('status', !status)
        const response = await updateUserService(id, data)
        responseAxios(response)
        if (response.status == 200) {
            emit("recargarDataUsers", 1)
        }
    }, icono, confirmar_button)
}

const sortData = (column) => {
    emit('update:users', sortList(column, props.users));
}

const showPermission = computed(() => {
    return (sessionStorage.permission) ? sessionStorage.permission : []
})
</script>
<style scoped>
.fa.fa-fw.fa-sort {
    font-size: 68%;
    cursor: pointer;
}
</style>