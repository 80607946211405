<template>
    <div class="modal fade" id="ModalNewRuta" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="title-header-modal">Nueva Ruta</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanForm"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label" >Nombre <span
                                    class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="nombre" placeholder="Ingrese una ruta">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal" id="btnCerrarModalAddRuta" @click="cleanForm">Cerrar</button>
                    <button v-if="loading == false" type="button" class="btn btn-primary" @click="saveRuta">Guardar</button>
                    <component v-else :is="ButtonLoadingComponent">Guardando</component>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, defineEmits } from 'vue'
import { storeRutaService } from '@/services/rutas'
import { responseAxios } from '@/Helper'
import ButtonLoadingComponent from '@/components/helpers/ButtonLoadingComponent.vue'

let nombre  = ref('')
let loading = ref(false)
let emit    = defineEmits(['recargarDataRutas'])

const saveRuta  = async() => {
    loading.value = true
    let data    = new FormData()
    data.append('nombre', nombre.value)

    let response = await storeRutaService(data)
    loading.value = false
    responseAxios(response)
    if(response.status == 200){
        cleanForm()
        emit('recargarDataRutas', 1)
        document.getElementById('btnCerrarModalAddRuta').click()
    }
}

const cleanForm = () => {
    nombre.value = ''
}
</script>
<style scoped>
#title-header-modal {
    margin-left: auto;
}
</style>