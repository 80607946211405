<template>
    <div class="row h-100">
      <div class="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
        <div class="login-form">
          <div class="text-center">
            <h3 class="title">Ingresar</h3>
            <p>Inicie sesión en su cuenta para comenzar a usar CXC</p>
          </div>
          <form  @submit="login">
            <div class="mb-4">
              <label class="mb-1 text-dark">Usuario</label>
              <input type="email" class="form-control form-control" placeholder="usuario@example.com" v-model="email">
            </div>
            <div class="mb-4 position-relative">
              <label class="mb-1 text-dark">Contraseña</label>
              <input type="password" id="dz-password" class="form-control" v-model="password">
              <span class="show-pass eye">

                <i class="fa fa-eye-slash"></i>
                <i class="fa fa-eye"></i>

              </span>
            </div>
            <div class="form-row d-flex justify-content-between mt-4 mb-2">
              <div class="mb-4">
                <div class="form-check custom-checkbox mb-3">
                  <input type="checkbox" class="form-check-input" id="customCheckBox1">
                  <label class="form-check-label" for="customCheckBox1">Recordar mis datos</label>
                </div>
              </div>
              <div class="mb-4">
                <a href="page-forgot-password.html" class="btn-link text-primary">¿Olvidaste tu contraseña?</a>
              </div>
            </div>
            <div class="text-center mb-4">
              <button type="submit" class="btn btn-primary btn-block" @click="login">Ingresar</button>
            </div>
          </form>
          <div class="mb-3">
            <ul class="d-flex align-self-center justify-content-center">
              <li><a @click="loginSocialFacebook" class="fab fa-facebook-f btn-facebook"></a></li>
              <li>
                  <a @click="loginSocialGoogle" class="fab fa-google-plus-g btn-google-plus mx-2"></a>
              </li>
            </ul>
          </div>
          <p class="text-center">¿No estas registrado?
            <a class="btn-link text-primary" href="mailto:hola@mhuri.io">Contáctanos</a>
          </p>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6">
        <div class="pages-left h-100">
          <div class="login-content">
            <!---<a href="#"><img src="@/assets/logo-full.png" class="mb-3 logo-dark" alt=""></a>-->
            <a href="#"><img src="@/assets/logi-white.png" class="mb-3 logo-light" alt=""></a>

            <p>CXC:Sistema de administración de cuentas por cobrar y gestión de cartera.</p>
          </div>
          <div class="login-media text-center">
            <img src="@/assets/login.png" alt="">
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
  //import { useRouter } from 'vue-router'
  import {responseAxios} from '@/Helper'
  import {loginService, loginGoogleService, loginFacebookService, getUserGoogleToken, getUserInfoProfileGoogle} from '@/services/auth'
  import {initFBSDK} from '@/services/sdk_facebook'
  import { onMounted, ref} from "vue"
  import { googleOneTap, googleLogout, googleSdkLoaded } from "vue3-google-login"

  //const router  = useRouter()
  let email     = ''
  let password  = ''
  let facebook_id = ref(null)

  onMounted(async () => {
    googleOneTap()
      .then((response) => {
        // This promise is resolved when user selects an account from the the One Tap prompt
        loginSocialGoogle(response)
      })
      .catch((error) => {
        console.log("Handle the error", error)
        googleLogout()
      })

      facebook_id.value = await initFBSDK()
  })

  const login = async (e) => {
    e.preventDefault();
    let data = new FormData()
    data.append('email', email)
    data.append('password', password)

    const response = await loginService(data)
    responseAxios(response)
    if(response.status == 200){
      window.location.href = window.location.origin + "/"
    }          
  }

  const loginSocialGoogle = async () => {
    googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: 
              'https://www.googleapis.com/auth/userinfo.profile',
            redirect_uri:  process.env.VUE_APP_GOOGLE_REDIRECT_URI,
            callback: response => {
              if (response.code) {
                sendCodeToBackend(response.code);
              }
            }
          })
          .requestCode();
      });
  }

  const sendCodeToBackend = async (code) => {
      try {

        let response = await getUserGoogleToken(code)
        if(response.status != 200){
          responseAxios(response)
          return 0
        }
        
        let token       = response.data.access_token
        let userResponse= await getUserInfoProfileGoogle(token)
        if(response.status != 200){
          responseAxios(response)
          return 0
        }

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          console.log(userResponse.data);
          let data = userResponse.data
          let google_id = data.sub
          let nombre    = data.given_name
          let apellido  = data.family_name || ''
          let email     = data.email

          let datos = new FormData()
          datos.append("google_id", google_id)
          datos.append("password", google_id)
          datos.append("nombre", nombre)
          datos.append("apellido", apellido)
          datos.append("email", email)

          let response = await loginGoogleService(datos)
          responseAxios(response)
          if(response.status == 200){
            window.location.href = window.location.origin + "/"
          }

        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
  }

  const loginSocialFacebook = async () => {
        if(facebook_id.value === undefined || facebook_id.value === null){
          window.FB.login(function(response) {
            facebook_id.value = response.authResponse.userID
            window.FB.api('/' + response.authResponse.userID,{fields: 'name, email, age_range'}, function(response) {
              loginInitFacebook(facebook_id.value, response.name, response.email, response.age_range.min)
            });
            
          }, {scope: 'public_profile, email, user_age_range'});

          return 0
        }

        window.FB.api('/'+facebook_id.value,{fields: 'name, email, age_range'}, function(response) {
          console.log("Logueado")
          console.log(response)
          loginInitFacebook(facebook_id.value,response.name, response.email, response.age_range.min)
        });

        return 0
  }

  const loginInitFacebook = async(facebookID, nombre, email, edad) => {
    let data = new FormData()
    data.append('facebook_id', facebookID)
    data.append('email', email)
    data.append('nombre', nombre)
    data.append('edad', edad)

    let response = await loginFacebookService(data)
    responseAxios(response)
    if(response.status == 200){
      window.location.href = window.location.origin + "/"
    }
  }
</script>